import gql from 'graphql-tag'
// import chatFragment from './../ChatFragment'

export const CHATS_QUERY_HIGEST = gql `
  query getChatMessageSuport_H($room: String) {
    chats: getChatMessageSuport_H(room: $room) {
      name
  flag
  # from
  messages{
    body
    date
    time
    read
    flag_to
    author{
      name
      photo
      id
    }
  }
    }
  }
`

export const CHATS_ROOM_QUERY_HIGEST = gql `
  query getReciveMessageSuport_H {
    recivedChats_H: getReciveMessageSuport_H {
      room :name
      testID: test
      from
      messages{
        body
        time
        read
        date
        flag_to
        author{
          id
          name
          photo
          email
        }
      }
    }
  }
`